function generateUUID() {
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now()
  }
  let uuid = 'xxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
function timeStampToTime(timestamp) {
  ;('' + timestamp).length <= 10 && (timestamp = timestamp * 1000)
  const date = new Date(timestamp)
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const y = date.getFullYear()
  const MM = date.getMonth() + 1
  const MMStr = MM < 10 ? '0' + MM : MM //月补0
  const d = date.getDate()
  const dStr = d < 10 ? '0' + d : d //天补0
  const h = date.getHours()
  const hStr = h < 10 ? '0' + h : h //小时补0
  const m = date.getMinutes()
  const mStr = m < 10 ? '0' + m : m //分钟补0
  return y + '-' + MMStr + '-' + dStr + ' ' + hStr + ':' + mStr
}
// 时辰转换
function todayTimeFormat(e) {
  if (e > 0 && e < 7) {
    return '凌晨'
  } else if (e >= 7 && e <= 11) {
    return '上午'
  } else if (e >= 11 && e < 13) {
    return '中午'
  } else if (e >= 13 && e < 18) {
    return '下午'
  } else if (e >= 18 && e < 24) {
    return '晚上'
  } else {
    return ''
  }
}

// 星期格式转换 0-6：周日到周六
function weekFormat(e, prefix = '周') {
  switch (e) {
    case 0:
      return prefix + '日'
    case 1:
      return prefix + '一'
    case 2:
      return prefix + '二'
    case 3:
      return prefix + '三'
    case 4:
      return prefix + '四'
    case 5:
      return prefix + '五'
    case 6:
      return prefix + '六'
    default:
      return ''
  }
}
// 是否显示周几
function isShowWeekDay(sub, weekDay) {
  const currentWeekDay = new Date().getDay()
  const dayTime = 1000 * 60 * 60 * 24
  if (sub > dayTime * 2 && weekDay != 0 && sub <= dayTime * currentWeekDay) {
    return true
  } else {
    return false
  }
}
function formatTime2(fmt, timestamp) {
  let date = new Date(timestamp)
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}
// 仿微信时间格式转换 @时间戳毫秒
function wechatTimeFormat(time) {
  if (!time) {
    return
  }
  let today = new Date().getTime()
  let sub = today - time
  let day = 1000 * 60 * 60 * 24
  time = parseInt(time * 1000)
  let timeDate = new Date(time)
  let currentYear = new Date().getFullYear()
  let getYear = new Date(time).getFullYear()
  const HHmm = `${formatTime2('hh', timeDate)}:${formatTime2('mm', timeDate)}`
  let showWeekDay = isShowWeekDay(sub, timeDate.getDay())
  let currentDay = new Date().getDate()
  let getDay = new Date(time).getDate()
  let subDay = currentDay - getDay
  if (currentYear > getYear) {
    return `${formatTime2('yyyy-MM-dd', timeDate)}${formatTime2('hh:mm', timeDate)}`
  } else if (showWeekDay) {
    return `${weekFormat(timeDate.getDay())}${HHmm}`
  } else if (subDay == 1) {
    return `昨天${HHmm}`
  } else if (sub <= day) {
    return HHmm
  } else {
    return `${formatTime2('MM-dd', timeDate)} ${formatTime2('hh:mm', timeDate)}`
  }
}

// 时间是否相隔5分钟
function isShowTime(time1, time2) {
  if (!time1) {
    return true
  }
  time1 = parseInt(time1 * 1000)
  time2 = parseInt(time2 * 1000)
  let duration = 1000 * 60 * 5
  if (time2 - time1 <= duration) {
    return false
  }
  return true
}

export { generateUUID, timeStampToTime, wechatTimeFormat, isShowTime }
