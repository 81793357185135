import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import mitt from 'mitt'
import config from './router/config.js'
import './assets/icon/iconfont.css'
const Mitt = mitt()
const app = createApp(App)
app.config.globalProperties.$config = config
let http = axios.create({
  baseURL: config.baseURL,
  timeout: 60000,
  headers: { 'X-Custom-Header': 'wtcs-cust-client' }
})
app.config.globalProperties.$http = http
app.config.globalProperties.$bus = Mitt
app.config.globalProperties.$defaultPrimary = {
  fontColor: '#fff',
  backColor: '#0074FF'
}

app.mount('#textbotapp')
