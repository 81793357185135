<template>
  <div>
    <div class="feedback" v-if="isShow">
      <div class="top-wrapper">
        <div class="title">新增反馈</div>
        <i class="close-icon iconfont icon-guanbi" @click="isShow = false" ></i>
      </div>
      <div class="content">
        <textarea
          class="content-input"
          v-model="msg"
          :minlength="1"
          :maxlength="150"
          :autosize="{ minRows: 3 }"
          placeholder="请输入"
          @blur="handleBlur"
          :autofocus="true">
        </textarea>
        <div class="errmsg" v-if="errMsg">{{ errMsg }}</div>
      </div>
      <div class="bottom">
        <button class="submit-btn" type="primary" @click="handleSubmit">提交</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()
const msg = ref(null)
const type = ref(null)
const isShow = ref(false)
const errMsg = ref(null)
// 提交反馈
const handleSubmit = () => {
  if (msg.value && /\S/.test(msg.value)) {
    let data = {
      botId: localStorage.getItem('botId'),
      msg: msg.value,
      senderId: localStorage.getItem('senderId'),
      type: type.value
    }
    proxy.$http.post('/textbot-server/app/client/feedback', data).then(({ data }) => {
      if (data.code == 0) {
        alert('反馈成功！')
        isShow.value = false
      } else {
        alert('反馈失败！')
      }
    })
  }
}
const handleBlur = () => {
  if (msg.value && /\S/.test(msg.value)) {
    errMsg.value = null
  } else {
    errMsg.value = '请输入反馈意见'
  }
}
const init = t => {
  type.value = t
  msg.value = null
  isShow.value = true
}
defineExpose({
  init
})
</script>
<style lang="scss" scoped>
.feedback {
  position: absolute;
  top: 64px;
  width: 100%;
  background-color: #fff;
  padding: 8px;
  box-sizing: border-box;
  .top-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .title {
      font-size: 14px;
      color: #31313f;
      font-weight: bold;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      color: #227fff;
    }
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    .submit-btn{
      background: #227fff;
      border: none;
      color: #ffffff;
      padding: 5px 10px;
      border-radius: 4px;
    }
  }
  .content {
    .content-input{
      width: 100%;
      border: 1px solid #ccc;
      outline: none;
      padding: 10px;
    }
    .errmsg {
      font-size: 12px;
      color: red;
    }
  }
}
</style>
