<template>
  <div class="wrapper">
    <div
      class="chart-container"
      :style="{
        width: styleConfig.styleConfig.dialogWidth + 'px',
        height: styleConfig.styleConfig.dialogHeight + 'px'
      }"
      v-if="!styleConfig.isClose"
    >
      <!-- 头部 -->
      <chartHeader :styleConfig="styleConfig" @close="handleClose" />

      <!-- 消息框 -->

      <chartMsg
        class="msg"
        :style="{
          height: `calc(100% - ${styleConfig.defaultConfig.showFooter ? '180' : '160'}px)`
        }"
        :styleConfig="styleConfig"
        :senderId="senderId"
        @feedback="handleFeedback"
      />
      <!-- 输入框 -->
      <chartInput :styleConfig="styleConfig" :senderId="senderId" />
      <!-- 反馈 -->
      <chartFeedback ref="feedbackRef" />
    </div>
    <!-- 关闭按钮 -->
    <chartCloseBnt
      v-if="!showFlag"
      class="close-btn"
      @close="handleClose"
      :styleConfig="styleConfig"
    />
  </div>
</template>
<script setup>
import chartHeader from '@/view/common/chartHeader'
import chartMsg from '@/view/common/chartMsg'
import chartCloseBnt from '@/view/common/chartCloseBnt'
import chartInput from '@/view/common/chartInput.vue'
import chartFeedback from '@/view/common/chartFeedback.vue'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { generateUUID } from '@/utils/index.js'
const { proxy } = getCurrentInstance()
const styleConfig = ref({
  defaultConfig: {},
  styleConfig: {
    headerColor: {},
    dialogColor: {},
    themeColor: {},
    userTipsColor: {},
    botTipsColor: {},
    sendIconColor: {},
    customIconColor: {}
  },
  quickFunctionConfig: {
    style: {},
    quickFunctionList: []
  },
  isClose: true
})
const handleClose = val => {
  styleConfig.value.isClose = val
}
const showFlag = ref(null)
// 手机端和pc端
const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
const senderId = ref(null)
const botId = ref(null)
// 初始化信息
const initInfo = () => {
  let id = localStorage.getItem('senderId')
  if (id) {
    senderId.value = id
  } else {
    let uuid = generateUUID()
    senderId.value = uuid
    localStorage.setItem('senderId', uuid)
  }
}

const getBotId = () => {
  let url = window.location.href
  let query = url.split("?").length == 2 ? url.split("?")[1] : null
  if(query){
    let params = query.split('&')
    if(params && params.length > 0){
      for (let i = 0; i < params.length; i++) {
        let parObj = params[i].split("=")
        let name = parObj[0]
        let val = parObj.length == 2 ? parObj[1] : null
        if(name == 'botId'){
          localStorage.setItem('botId', val)
          return val;
        }
      }
    }
  }
  let botId = localStorage.getItem('botId') || "1697130728810274816"
  localStorage.setItem('botId', botId)
  return botId
}

// 获取配置信息
const getStyleConfig = () => {
  botId.value = getBotId()
  if(!botId.value && botId.value == ''){
    console.error('未获取到机器人编号')
    return
  }
  proxy.$http.get(`/textbot-server/app/client/bot/${botId.value}`).then(({ data }) => {
    if (data.code == 0 && data.data) {
      document.title = data.data.title
      styleConfig.value = data.data
      styleConfig.value.isClose = !data.data.defaultConfig.openDialog
    }
  })
}
getStyleConfig()
initInfo()
const feedbackRef = ref(null)
const handleFeedback = type => {
  feedbackRef.value.init(type)
}
onMounted(() => {
  showFlag.value = _isMobile()
})
</script>
<style lang="scss" scoped>
.wrapper {
  .chart-container {
    position: fixed;
    right: 10px;
    bottom: 100px;
    z-index: 999;
    border-radius: 10px;
    background-color: #f7f8fa;
    overflow: hidden;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
    .msg {
      width: 100%;
    }
  }
  .close-btn {
    position: fixed;
    bottom: 20px;
    right: 10px;
  }
  @media screen and (max-width: 960px) {
    .chart-container {
      width: 100% !important;
      height: 100% !important;
      bottom: 0;
      right: 0;
      border-radius: 0;
    }
  }
}
</style>
