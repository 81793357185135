<template>
  <div
      class="chart-header"
      :style="{
      backgroundColor: data.headerColor.backColor,
      color: data.headerColor.fontColor
    }"
  >
    <div class="left">
      <img v-if="data.titleIcon && data.titleIcon != null" class="icon" :src="data.titleIcon"/>
      <i v-else class="icon iconfont icon-kefufill" :style="{color: data.headerColor.fontColor}"></i>
      <div>
        <div class="title">{{ data.title }}</div>
        <div class="sub-title">{{ data.subTitle }}</div>
      </div>
    </div>
    <!-- 关闭按钮图标 -->
    <div class="close-btn" v-if="data.defaultConfig.showCloseBtn" @click="handleClose">
      <img class="icon" v-if="data.closeIcon && data.closeIcon != ''" :src="data.closeIcon"/>
      <i v-else class="icon iconfont icon-guanbi" :style="{color: data.headerColor.fontColor, fontSize: '24px'}"></i>
    </div>
  </div>
</template>
<script setup>
import {computed, defineEmits, defineProps, getCurrentInstance} from 'vue'
const { proxy } = getCurrentInstance()

const props = defineProps({
  styleConfig: {
    type: Object, default: () => {
    }
  }
})
const data = computed(() => {
  let res = {}
  if (props.styleConfig) {
    res.headerColor = props.styleConfig.styleConfig.headerColor || proxy.$defaultPrimary
    res.titleIcon = props.styleConfig.styleConfig.titleIcon || null
    res.title = props.styleConfig.title || null
    res.subTitle = props.styleConfig.subTitle || null
    res.closeIcon = props.styleConfig.styleConfig.closeIcon
    res.defaultConfig = props.styleConfig.defaultConfig || {}
  }
  return res
})
const emit = defineEmits('close')
const handleClose = () => {
  emit('close', true)
}
</script>
<style lang="scss" scoped>
.chart-header {
  height: 64px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
  }

  .left {
    display: flex;
    align-items: center;

    > div:last-child {
      margin-left: 10px;
    }

    .title {
      font-size: 15px;
      font-weight: 600;
      line-height: 25px;
    }

    .sub-title {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 960px) {
  .chart-header {
    border-radius: 0;
  }
  .close-btn{
    display: none;
  }
}

.btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
