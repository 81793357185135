<template>
  <div @click="handleClose">
    <!-- 入口按钮图标 -->
    <div>
      <img class="img" v-if="data.entryIcon && data.entryIcon != null" :src="data.entryIcon" />
      <div class="icon" :style="{color: data.customIconColor.fontColor, backgroundColor: data.customIconColor.backColor}">
        <i class="iconfont icon-kefufill"></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import {defineProps, defineEmits, computed, getCurrentInstance} from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
  styleConfig: { type: Object, default: () => {} }
})
let flag = true
const data = computed(() => {
  let res = {}
  if (props.styleConfig) {
    res.entryIcon = props.styleConfig.styleConfig.entryIcon
    res.customIconColor = props.styleConfig.styleConfig.customIconColor || proxy.$defaultPrimary
    res.isClose = props.styleConfig.isClose
    flag = res.isClose
  }
  return res
})
const emit = defineEmits('close')
const handleClose = () => {
  flag = !flag
  emit('close', flag)
}
</script>
<style lang="scss" scoped>
.img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.icon{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  .iconfont{
    font-size: 30px;
  }
}
</style>
