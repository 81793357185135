<template>
  <div class="wrapper" ref="wrapperRef">
    <div class="chart-msg">
      <!-- 暂无更多数据 -->
      <div class="loading" v-if="noMore">暂无更多数据</div>
      <div class="loading" v-if="!noMore && !loading">下拉加载更多</div>
      <div class="loading" v-if="loading">数据加载中</div>
      <div
        v-for="(item, index) in msgList"
        :key="index"
        :class="`chatMsg${index}`"
        :ref="`chatMsg${index}`"
      >
        <!-- 时间 -->
        <div class="time" v-if="isShowTime(msgList[index - 1]?.timestamp, item.timestamp)">
          {{ wechatTimeFormat(item.timestamp) }}
        </div>
        <!-- 机器人消息 -->
        <div class="message-item" v-if="item.event == 'bot'">
          <div class="avatar">
            <img v-if="data.botAvatar && data.botAvatar != ''" :src="data.botAvatar" />
            <i v-else class="iconfont icon-jiqiren" :style="{
                backgroundColor: item.image ? '' : data.botTipsColor.backColor,
                color: data.botTipsColor.fontColor
              }"></i>
          </div>
          <div class="out-wrapper">
            <div
              class="message-content content2"
              :style="{
                backgroundColor: item.image ? '' : data.botTipsColor.backColor,
                color: data.botTipsColor.fontColor,
                padding: item.image ? '0' : '8px'
              }"
            >
              <!-- 文字消息 -->
              <div v-if="item.text" class="text-message">
                {{ item.text }}
              </div>
              <!-- 图片消息 -->
              <div v-if="item.image" class="img-message">
                <img :src="item.image" alt="" />
              </div>
              <!-- 音频消息 -->
              <!-- <myVideo
              v-if="item.type == 3"
              :myUrl="item.content"
              :reverse="true"
              :ref="`audios${index}`"
              @click="handleAudioPlay(index)"
            /> -->
              <!-- 视频消息 -->
              <!-- <videoPlayer v-if="item.type == 4" :src="item.content" :ref="`videos${index}`" /> -->
              <!-- 问题列表 -->
              <div v-if="item.quick_replies" class="question-list">
                <div class="title">以下是匹配的相似问题：</div>
                <div
                  @click="handleSendMsg(i)"
                  class="item"
                  v-for="(i, index) in item.quick_replies"
                  :key="index"
                >
                  {{ i }}
                </div>
              </div>
            </div>
            <div class="feedback">
              <i @click="handlefeedback(1)" class="iconfont icon-haoping" alt="" />
              <i @click="handlefeedback(2)" class="iconfont icon-chaping" alt="" style="margin-left: 10px" />
            </div>
          </div>
        </div>
        <!-- 用户消息 -->
        <div class="message-item reverse-msg" v-if="item.event == 'user'">
          <div class="avatar">
            <img v-if="data.userAvatar && data.userAvatar != ''" :src="data.userAvatar" />
            <i v-else class="iconfont icon-yonghu"  :style="{
                backgroundColor: item.image ? '' : data.userTipsColor.backColor,
                color: data.userTipsColor.fontColor
              }"></i>
          </div>
          <div
            class="message-content content1"
            :style="{
              backgroundColor: data.userTipsColor.backColor,
              color: data.userTipsColor.fontColor
            }"
          >
            <!-- 文字消息 -->
            <div class="text-message">{{ item.text }}</div>
          </div>
        </div>
      </div>
      <!-- loading -->
      <div class="message-item" v-if="botSendLoading">
        <div class="avatar">
          <i class="iconfont icon-jiqiren" :style="{backgroundColor: data.botTipsColor.backColor, color: data.botTipsColor.fontColor}"></i>
        </div>
        <div class="out-wrapper">
          <div class="message-content content2" :style="{ backgroundColor: data.botTipsColor.backColor,  color: data.botTipsColor.fontColor, padding: '8px'}">
            <div  class="text-message">
              <bot-loading></bot-loading>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  getCurrentInstance,
  onMounted,
  nextTick,
  computed,
  watch,
  defineEmits, onUnmounted
} from 'vue'
import myVideo from '@/components/myVideo'
import videoPlayer from '@/components/video-player.vue'
import BotLoading from '@/components/BotLoading'
import BScroll from 'better-scroll'
import { wechatTimeFormat, isShowTime } from '@/utils/index.js'
const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => {}
  },
  senderId: { type: String }
})
const data = computed(() => {
  return props.styleConfig.styleConfig
})
const { proxy } = getCurrentInstance()
const msgList = ref([])
// 音频播放
const curPlay = ref(null)
const handleAudioPlay = index => {
  for (let i = 0; i < msgList.value.length; i++) {
    if (msgList.value[i].content != '' && proxy.$refs[`audios${i}`]) {
      proxy.$refs[`audios${i}`][0].handlePause()
    }
  }
  if (curPlay.value == index) {
    proxy.$refs[`audios${index}`][0].handlePause()
    curPlay.value = null
  } else {
    proxy.$refs[`audios${index}`][0].handlePlay()
    curPlay.value = index
  }
}
let bscroll = null
const wrapperRef = ref(null)
const initScroll = () => {
  bscroll = new BScroll(wrapperRef.value, {
    probeType: 2,
    mouseWheel: true,
    pullDownRefresh: true,
    // 纵向滚动
    scrollY: true,
    click: true
  })
  // 上拉加载更多
  bscroll.on('pullingDown', () => {
    if (noMore.value) {
      bscroll.finishPullDown()
      return
    }
    loadMore().then(() => {
      bscroll.finishPullDown()
    })
  })
}

// 滚动到底部
const scrollIndex = ref(0)
const scrollToBottom = () => {
  bscroll.refresh()
  nextTick(() => {
    bscroll.scrollToElement(proxy.$refs[`chatMsg${scrollIndex.value}`][0], 0)
  })
}
// 加载更新
const loadMore = () => {
  return getHistoryList()
}
// 获取历史数据
const noMore = ref(false)
const loading = ref(false)
const getHistoryList = () => {
  return new Promise(resolve => {
    let query = { senderId: props.senderId, limit: 10 }
    if (loading.value) {
      resolve()
      return
    }
    if (msgList.value.length > 0) {
      query.lastMsgId = msgList.value[0].id
    }
    loading.value = true
    proxy.$http.get('/textbot-server/app/client/msg/list', { params: query }).then(({ data }) => {
      if (data.code == 0 && data.data.list.length > 0) {
        let list = handleData(data.data.list.reverse())
        msgList.value = [...list, ...msgList.value]
        if (query.lastMsgId) {
          scrollIndex.value = 0
        } else {
          scrollIndex.value = msgList.value.length-1
        }
        setout()
      } else if (data.data.list.length == 0) {
        noMore.value = true
      }
      loading.value = false
      resolve(data)
    })
  })
}

const handleSendMsg = content => {
  sendMsg(content)
}
onMounted(() => {
  initScroll()
  proxy.$bus.on('send', sendMsg)
})
onUnmounted(() => {
  proxy.$bus.off('send', sendMsg)
})

// 发送消息
const isSend = ref(false)
const botSendLoading = ref(false)
const sendMsg = val => {
  let data = { botId: localStorage.getItem('botId'), sender: props.senderId, message: val }
  if (!isSend.value) {
    msgList.value.push({ text: val, event: 'user' })
    scrollIndex.value = msgList.value.length - 1
    setout()
    botSendLoading.value = true
    proxy.$http.post('/textbot-server/app/client/send/msg', data).then(({ data }) => {
      isSend.value = true
      botSendLoading.value = false
      if (data.code == 0) {
        data.data.botMsgList.forEach(item => {
          let obj = { event: 'bot' }
          let arr = ['text', 'image']
          arr.forEach(i => {
            if (item[i]) {
              obj[i] = item[i]
              msgList.value.push(obj)
            }
          })
        })
        if (data.data.intentList && data.data.intentList.length > 0) {
          let obj = { event: 'bot', quick_replies: [] }
          data.data.intentList.forEach(item => {
            if (item.examples && item.examples.length > 0) {
              obj.quick_replies.push(item.examples[0])
            }
          })
          if (obj.quick_replies.length > 0) {
            msgList.value.push(obj)
          }
        }
        scrollIndex.value = msgList.value.length - 1
        isSend.value = false
        setout()
      }
    })
  }
}
let time
const setout = () => {
  if (time) {
    clearTimeout(time)
    time = null
  }
  time = setTimeout(() => {
    scrollToBottom()
  }, 1)
}
// 处理数据
const handleData = list => {
  let res = []
  list.forEach(item => {
    let obj = {
      id: item.id,
      text: item.text,
      event: item.event,
      timestamp: item.timestamp
    }
    res.push(obj)
    if (item.data && Object.keys(item.data).length > 0) {
      for (let key in item.data) {
        if (item.data[key] && key == 'image') {
          let obj = { id: item.id, event: item.event, timestamp: item.timestamp }
          obj[key] = item.data[key]
          res.push(obj)
        }
      }
    }
  })
  return res
}
watch(
  () => props.styleConfig,
  newVal => {
    if (!newVal.isClose || newVal.defaultConfig.openDialog) {
      getHistoryList()
    }
    if (newVal.welcomeMsg && newVal.defaultConfig.showWelcomeMsg) {
      msgList.value.push({ text: newVal.welcomeMsg, event: 'bot' })
    }
  },
  { immediate: true }
)
// 反馈
const emit = defineEmits(['feedback'])
const handlefeedback = type => {
  emit('feedback', type)
}
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  overflow: hidden;
}
.chart-msg {
  overflow-y: auto;
  padding: 8px;
  .time {
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    color: #888888;
    margin: 10px 0;
  }
  .avatar{
    background-color: #ffffff;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 8px;
    flex-shrink: 0;
    img{
      width: 35px;
      height: 35px;
    }
    .iconfont{
      display: block;
      font-size: 20px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
  }
  .out-wrapper{

  }
  .message-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 20px;

    .out-wrapper {
      position: relative;

      .feedback {
        text-align: right;
        padding-right: 10px;
      }
    }

    .message-content {
      margin: 8px;
      background-color: #ffffff;
      padding: 8px;
      margin-bottom: 10px;
    }
    .question-list {
      font-size: 15px;
      line-height: 2;
      .item {
        color: #227fff;
        cursor: pointer;
      }
    }
    .content1 {
      border-radius: 10px 0 10px 10px;
    }
    .content2 {
      border-radius: 0 10px 10px 10px;
    }
    .img-message {
      img {
        width: 150px;
        border-radius: 5px;
      }
    }
    .text-message {
      display: flex;
      flex-direction: row-reverse;
      font-size: 14px;
    }
  }
  .reverse-msg {
    flex-direction: row-reverse;
  }
  .loading {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #666666;
  }
}
</style>
