<template>
  <div class="input-wrapper">
    <div v-if="data.quickFunctionConfig && data.quickFunctionConfig.quickFunctionList && data.quickFunctionConfig.quickFunctionList.length > 0"
      class="quick-list scroll"
      :style="{
        backgroundColor: data.quickFunctionConfig.style.backColor,
        color: data.quickFunctionConfig.style.fontColor
      }"
    >
      你想：
      <div
        class="quick-item"
        v-for="(item, index) in data.quickFunctionConfig.quickFunctionList"
        :key="index"
        @click="handleQuickClick(item)"
        :style="{ backgroundColor: item.style.backColor, color: item.style.fontColor }"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="chart-input">
      <input class="input" v-model="input" placeholder="请输入" enterkeyhint="send" @keyup.enter="handleSendMsg">
      <img class="sendimg" v-if="data.sendIcon && data.sendIcon != ''" @click="handleSendMsg" :src="data.sendIcon" />
      <i v-else @click="handleSendMsg" class="sendicon iconfont icon-daohang" :style="{ backgroundColor: data.sendIconColor.backColor, color: data.sendIconColor.fontColor }"></i>
    </div>
    <!-- 页脚 -->
    <div v-if="data.defaultConfig.showFooter" class="footer">技术支持云知美科技</div>
  </div>
</template>
<script setup>
import { defineProps, ref, getCurrentInstance, computed } from 'vue'
const props = defineProps({
  styleConfig: { type: Object, default: () => {} },
  senderId: { type: String }
})
console.log(props.styleConfig.sendIconColor)
const data = computed(() => {
  let res = {}
  if (props.styleConfig) {
    res.sendIcon = props.styleConfig.styleConfig.sendIcon
    res.sendIconColor = props.styleConfig.styleConfig.sendIconColor || {backColor: proxy.$defaultPrimary.fontColor, fontColor: proxy.$defaultPrimary.backColor}
    res.quickFunctionConfig = props.styleConfig.quickFunctionConfig
    res.defaultConfig = props.styleConfig.defaultConfig
  }
  return res
})

const { proxy } = getCurrentInstance()
const input = ref(null)
const handleSendMsg = () => {
  if (/\S/.test(input.value) && input.value) {
    proxy.$bus.emit('send', input.value)
    input.value = null
  }
}

const handleQuickClick = val => {
  if (val.type == 1) {
    proxy.$bus.emit('send', val.msg)
    // 意图
  } else {
    // 外链
    window.open(val.content, '_self')
  }
}
</script>
<style lang="scss" scoped>
.input-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.chart-input {
  padding: 8px;
  display: flex;
  background-color: #ffffff;

  .input {
    box-shadow: unset;
    background-color: #f8f8fa;
    min-height: unset;
    resize: none;
    box-sizing: border-box;
    border: none;
    flex: 1;
    padding: 0 10px;
    outline: none;
  }
  .sendimg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .sendicon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.quick-list {
  height: 42px;
  padding: 5px 5px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 12px;
  .quick-item {
    padding: 5px 8px;
    margin-right: 8px;
    border-radius: 30px;
    white-space: nowrap;
    cursor: pointer;
  }
}
.footer {
  background-color: #ffffff;
  height: 20px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #888888;
  border-radius: 0 0 10px 10px;
}
.scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background: #ededed;
    border-radius: 10px;
  }
}
@media screen and (max-width: 960px) {
  .footer {
    border-radius: 0;
  }
}
</style>
